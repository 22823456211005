import React, { useState } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Modal, ScrollView, Dimensions } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useFocusEffect } from '@react-navigation/native';
import { useAuth } from '../AuthContext';

const BASE_URL = 'https://billingapp.ryan.k736.net/api';
const BASE_URL_DEV = 'http://localhost:3000';
const API_URL = __DEV__ ? BASE_URL_DEV : BASE_URL;

const { width } = Dimensions.get('window');

const useInput = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);
  return { value, onChangeText: setValue };
};

export default function InvoiceScreen() {
  const { user, token, login, logout, loading } = useAuth();
  const [visibleInvoice, setVisibleInvoice] = useState(false);
  const [visibleCustomer, setVisibleCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState(null);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const addressInput = useInput();
  const stateInput = useInput();
  const cityInput = useInput();
  const zipInput = useInput();
  const amountInput = useInput();
  const firstNameInput = useInput();
  const lastNameInput = useInput();
  const passwordInput = useInput();
  const emailInput = useInput();

  useFocusEffect(
    React.useCallback(() => {
      fetchCustomers();
    }, [token])
  );

  const createUser = async (user) => {
    try {
      const response = await fetch(`${API_URL}/account`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      }, [token]);
      
      if (response.ok){
        const newUser = await response.json();
        console.log(newUser);
        return newUser;      
      }else {
        return error
      }
      
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  const createTransaction = async(trans) => {
    try {
      console.log(`Sending POST request to ${API_URL}/trans`);
      const response = await fetch(`${API_URL}/trans`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(trans),
      }, [token]);
      if (response.ok){
        const newTrans = await response.json();
        console.log('New transaction created:', newTrans);
        return newTrans;
      }else{
        return error
      }
    } catch (error) {
      console.error('Error creating Transaction:', error);
      return null;
    }
  }


  const fetchCustomers = async () => {
    try {
      const response = await fetch(`${API_URL}/accounts`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        },  [token]);
      if (response.ok){
        const data = await response.json();
        setCustomers(data);
      }else{
        console.error('Error Fetching customers', response.status)
        setCustomers([]);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setCustomers([]);
    }
  };

  const handleCreateUser = async () => {
    const newUser = {
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
      email: emailInput.value,
      password: passwordInput.value,
    };
    
    try {
      const createdUser = await createUser(newUser);
      if (createdUser) {
        showMessage('User Created Successfully!');
        firstNameInput.onChangeText('');
        lastNameInput.onChangeText('');
        emailInput.onChangeText('');
        passwordInput.onChangeText('');
      }else {
        showMessage('Failed to create user.', true)
      }
    } catch (error) {
      showMessage('Error Creating user: ' + error.message, true);
    }
  };

  const handleCreateTransaction = async () => {
    if (!selectedCustomer || !amountInput.value) {
      console.log('Error: Customer or amount not selected');
      return;
    }
    const amountasInt = parseInt(amountInput.value, 10);
    if(isNaN(amountasInt)) {
      console.log('Error: Amount is not a number');
      return;
    }
    const newTransaction = {
      number: selectedCustomer.number,
      amount: amountasInt,
      trans_type: "invoice",
      JWT: "JWT"
    };

    try {
      const response = await createTransaction(newTransaction, selectedCustomer.id);
      if (response) {
        showMessage('Invoice created Successfully!');
        await fetchCustomers();
        setSelectedCustomer(null);
        setSelectedCustomerAddress(null);
        setShowAddressFields(false);
        amountInput.onChangeText('');
      }else{
        showMessage('Failed to create invoice.', true)
      }    
    } catch (error) {
      showMessage('Error creating invoice: ' + error.message, true);
    }
  };

  const handleAddBillingAddress = async () => {
    if (!selectedCustomer) {
      console.log('Error: Customer not selected');
      return;
    }
    const newAddress = {
      account_id: selectedCustomer.id,
      address: addressInput.value,
      state: stateInput.value,
      city: cityInput.value,
      zipcode: zipInput.value,
    };
    try {
      const response = await fetch(`${API_URL}/billing-address`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newAddress),
      }, [token]);
      if (response.ok) {
        const updatedCustomer = await response.json();
        showMessage('Billing address added successfully!');
        setSelectedCustomer(updatedCustomer);
        setSelectedCustomerAddress(updatedCustomer.billing_address);
        setShowAddressForm(false);
      } else {
        showMessage('Failed to add billing Address')
      }
    } catch (error) {
      showMessage('Error adding billing address:' + error.message, true);
    }
  };

  const handleCustomerSelect = async (customerId) => {
    console.log('Customer selected:', customerId);
    if (customerId) {
      try {
        const response = await fetch(`${API_URL}/account/${customerId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          const errorData = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorData}`);
        }
  
        const customerData = await response.json();
        console.log('Customer data:', customerData);
        setSelectedCustomer(customerData);
  
        if (customerData.billing_address) {
          setSelectedCustomerAddress(customerData.billing_address);
          console.log('Address found:', customerData.billing_address);
        } else {
          setSelectedCustomerAddress(null);
          console.log('No address found');
        }
  
        setShowAddressFields(true);
        console.log('Show address fields:', true);
      } catch (error) {
        console.error('Error fetching customer address:', error);
        setSelectedCustomer(null);
        setSelectedCustomerAddress(null);
        setShowAddressFields(false);
        showMessage(`Error: ${error.message}`, true);
      }
    } else {
      setSelectedCustomer(null);
      setSelectedCustomerAddress(null);
      setShowAddressFields(false);
      console.log('Show address fields:', false);
    }
  };

  const renderAddressFields = () => {
    console.log('Rendering address fields. Show:', showAddressFields);
    if (!showAddressFields) return null;
    if (selectedCustomerAddress) {
      return (
        <View>
          <Text style={styles.label}>Billing Address:</Text>
          <Text>{selectedCustomerAddress.address}</Text>
          <Text>{selectedCustomerAddress.city}, {selectedCustomerAddress.state} {selectedCustomerAddress.zipcode}</Text>
        </View>
      );
    }
    if (showAddressForm) {
      return (
        <View>
          <Text style={styles.label}>Add Billing Address:</Text>
          {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
          {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
          <TextInput style={styles.input} placeholder='Address' {...addressInput} />
          <TextInput style={styles.input} placeholder='State' {...stateInput} />
          <TextInput style={styles.input} placeholder='City' {...cityInput} />
          <TextInput style={styles.input} placeholder='Zipcode' {...zipInput} />
          <TouchableOpacity style={[styles.button, styles.submitButton]} onPress={handleAddBillingAddress}>
            <Text style={styles.buttonText}>Add Address</Text>
          </TouchableOpacity>
        </View>
      );
    }
    return (
      <View>
        <Text style={styles.label}>No billing address found.</Text>
        <TouchableOpacity style={[styles.button, styles.submitButton]} onPress={() => setShowAddressForm(true)}>
          <Text style={styles.buttonText}>Add Address</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const showMessage = (message, isError = false) => {
    if (isError) {
      setErrorMessage(message);
    }else{
      setSuccessMessage(message);
    }
  
    setTimeout(() => {
      if (isError) {
        setErrorMessage('');
      } else {
        setSuccessMessage('');
      }
    }, 3000)
  }

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={() => setVisibleInvoice(true)}>
          <Text style={styles.buttonText}>New Invoice</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={() => setVisibleCustomer(true)}>
          <Text style={styles.buttonText}>New Customer</Text>
        </TouchableOpacity>
      </View>

      {/* Customer Modal */}
      <Modal visible={visibleCustomer} animationType="slide" transparent={true} onRequestClose={() => setVisibleCustomer(false)}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <ScrollView>
              <Text style={styles.modalTitle}>Create New Customer</Text>
              {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
              {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
              <TextInput style={styles.input} placeholder='First Name' {...firstNameInput} />
              <TextInput style={styles.input} placeholder='Last Name' {...lastNameInput} />
              <TextInput style={styles.input} placeholder='Email' {...emailInput} />
              <TextInput style={styles.input} placeholder='Password' secureTextEntry {...passwordInput} />
              <View style={styles.buttonRow}>
                <TouchableOpacity style={[styles.button, styles.dismissButton]} onPress={() => setVisibleCustomer(false)}>
                  <Text style={styles.buttonText}>Dismiss</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, styles.submitButton]} onPress={handleCreateUser}>
                  <Text style={styles.buttonText}>Submit</Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>

      {/* Invoice Modal */}
      <Modal visible={visibleInvoice} animationType="slide" transparent={true} onRequestClose={() => setVisibleInvoice(false)}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <ScrollView>
              <Text style={styles.modalTitle}>Create New Invoice</Text>
              {successMessage ? <Text style={styles.successMessage}>{successMessage}</Text> : null}
              {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
              <Text style={styles.label}>Select Customer:</Text>
              <Picker
                selectedValue={selectedCustomer ? selectedCustomer.id : ''}
                onValueChange={(itemValue) => handleCustomerSelect(itemValue)}
                style={styles.picker}
              >
                <Picker.Item label="Select a customer" value="" />
                {Array.isArray(customers) && customers.length > 0 ? (
                  customers.map((customer) => (
                    <Picker.Item key={customer.id} label={`${customer.firstName} ${customer.lastName}`} value={customer.id} />
                  ))
                ) : (
                  <Picker.Item label="No customers found" value="" />
                )}
              </Picker>
              {renderAddressFields()}
              <Text style={styles.label}>Amount:</Text>
              <TextInput style={styles.input} placeholder='Amount' keyboardType="numeric" {...amountInput} />
              <View style={styles.buttonRow}>
                <TouchableOpacity style={[styles.button, styles.dismissButton]} onPress={() => setVisibleInvoice(false)}>
                  <Text style={styles.buttonText}>Dismiss</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, styles.submitButton]} onPress={handleCreateTransaction}>
                  <Text style={styles.buttonText}>Submit</Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
  },
  buttonContainer: {
    width: width * 0.8,
    maxWidth: 400,
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#007AFF',
    padding: 15,
    borderRadius: 5,
    width: '100%',
    alignItems: 'center',
    marginBottom: 15,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 25,
    borderRadius: 15,
    width: width * 0.9,
    maxWidth: 400,
    maxHeight: '80%',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    height: 50,
    width: '100%',
    borderColor: '#ddd',
    borderWidth: 1,
    marginBottom: 15,
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'white',
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 10,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  dismissButton: {
    backgroundColor: '#FF3B30',
    width: '48%',
  },
  submitButton: {
    backgroundColor: '#34C759',
    width: '48%',
  },
  picker: {
    height: 50,
    width: '100%',
    marginBottom: 15,
    backgroundColor: 'white',
    borderColor: '#ddd',
    borderWidth: 1,
    borderRadius: 5,
  },
  successMessage: {
    color: 'green',
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 16,
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 16,
  },
});