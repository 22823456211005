import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const BASE_URL = 'https://billingapp.ryan.k736.net/api'
const BASE_URL_DEV = 'http://localhost:3000'

const API_URL = __DEV__ ? BASE_URL_DEV : BASE_URL;

export default function AccountScreen() {
  return (
    <View style={styles.container}>
      <Text>Account Screen</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});