import React, { createContext, useState, useContext, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';



const BASE_URL = 'https://billingapp.ryan.k736.net/api'
const BASE_URL_DEV = 'http://localhost:3000'

const API_URL = __DEV__ ? BASE_URL_DEV : BASE_URL;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);

    useEffect(() => {  
        checkToken();
    }, []);

    const checkToken = async () => {
        try {
            const token = await AsyncStorage.getItem('userToken');
            if (storedToken) {
                setToken(storedToken);
                const response = await fetch(`${API_URL}/auth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    },
                });
                if (response.ok) {
                    const userData = await response.json();
                    setUser(userData);
                } else {
                    await logout();
                }
            } 
            } catch (error) {
                console.error('Error checking token:', error);
        } finally {
            setLoading(false);
        }
    };

    const register = async (firstName, lastName, email, password) => {
        try {
            const response = await fetch(`${API_URL}/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                },
                body: JSON.stringify({firstName, lastName, email, password})
                });
            if (response.ok) {
                console.log('Registration successful');
                const userData = await response.json();
                console.log('User data:', userData)
                
                const loginSuccess = await login (email, password)
                if (loginSuccess) {
                    console.log('Auto-login after registration successful');
                    return true;
                }else {
                    console.log('Auto-login after registration failed');
                    return false;
                }
            } else {
                const errorData = await response.json();
                console.error('Registration failed:', errorData);
                return false;
                }
            }catch (error) {
                console.error('Error registering:', error);
                return false;
            } 
        }

    const login = async (email, password) => {
        console.log('The API_URL is:', API_URL);
        console.log('Attempting to login with URL:', `${API_URL}/auth/login`);
        try {
            const response = await fetch(`${API_URL}/auth/login`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                });
                if (response.ok) {
                    console.log('Login successful')
                    const { token, user } = await response.json();
                    await AsyncStorage.setItem('userToken', token);
                    console.log('Token stored:', token);
                    setUser(user);
                    setToken(token);
                    console.log('User state updated:', user);
                    return true;
                }
                return false;
            } catch (error) {
            console.error('Error logging in:', error);
            return false;
            }
    };

    const logout = async () => {
        try {
            await AsyncStorage.removeItem('userToken');
            setUser(null);
            setToken(null);
        } catch(error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, token, login, logout, register, loading}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
   const context = useContext(AuthContext);
   if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
   }
   return context;
}
