import React, { useState } from 'react';
import { View, TextInput, Button, StyleSheet, Alert, Text, TouchableOpacity, Dimensions } from 'react-native';
import { useAuth } from '../AuthContext';

const BASE_URL = 'https://billingapp.ryan.k736.net/api';
const BASE_URL_DEV = 'http://localhost:3000';
const API_URL = __DEV__ ? BASE_URL_DEV : BASE_URL;

const RegisterScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const { register } = useAuth();

  const handleRegister = async () => {
    const success = await register(firstName, lastName, email, password);
    console.log('Registration success:', success);
    if (!success) {
      Alert.alert('Registration Failed', 'Please enter your information in again.');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.formContainer}>
        <TextInput
          style={styles.input}
          placeholder="First Name"
          value={firstName}
          onChangeText={setfirstName}
          keyboardType="default"
          autoCapitalize="words"
        />
        <TextInput
            style={styles.input}
            placeholder="Last Name"
            value={lastName}
            onChangeText={setlastName}
            keyboardType="default"
            autoCapitalize="words"
        />
        <TextInput
          style={styles.input}
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholder="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
        />
        <TouchableOpacity style={styles.button} onPress={handleRegister}>
          <Text style={styles.buttonText}>Register</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.createAccountContainer} onPress={() => navigation.navigate('Login')}>
          <Text style={styles.createAccountText}>Already have an account? Login</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
  },
  formContainer: {
    width: width * 0.8,
    maxWidth: 400,
    alignItems: 'center',
  },
  input: {
    height: 50,
    width: '100%',
    borderColor: '#ddd',
    borderWidth: 1,
    marginBottom: 15,
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'white',
  },
  button: {
    backgroundColor: '#007AFF',
    padding: 15,
    borderRadius: 5,
    width: '100%',
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  createAccountContainer: {
    marginTop: 20,
  },
  createAccountText: {
    color: '#007AFF',
    fontSize: 14,
  },
});

export default RegisterScreen;