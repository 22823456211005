import 'react-native-reanimated';
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { AuthProvider, useAuth } from './AuthContext';
import BalanceScreen from './screens/BalanceScreen';
import AccountScreen from './screens/AccountScreen';
import InvoiceScreen from './screens/InvoiceScreen';
import ProfileScreen from './screens/ProfileScreen';
import LoginScreen from './screens/LoginScreen';
import LoadingScreen from './screens/LoadingScreen';
import RegisterScreen from './screens/RegisterScreen';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const ProfileIcon = ({ navigation }) => (
  <TouchableOpacity onPress={() => navigation.navigate('Profile')}>
    <Ionicons name="person-circle-outline" size={24} color="#007AFF" style={{ marginRight: 15 }} />
  </TouchableOpacity>
);

function DrawerNavigator() {
  return (
    <Drawer.Navigator>
      <Drawer.Screen name="Balance" component={BalanceScreen} />
      <Drawer.Screen name="Account" component={AccountScreen} />
      <Drawer.Screen name="Invoice" component={InvoiceScreen} />
    </Drawer.Navigator>
  );
}

function AppNavigator() {
  const { user, loading, logout } = useAuth();
  console.log('Current user state:', user);
  console.log('Loading state:', loading);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerStyle: {
            backgroundColor: '#f5f5f5',
          },
          headerTintColor: '#007AFF',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        }}
      >
        {user ? (
          <>
            <Stack.Screen
              name="Main"
              component={DrawerNavigator}
              options={({ navigation }) => ({
                headerRight: () => (
                  <View style={styles.headerRightContainer}>
                    <TouchableOpacity
                      onPress={logout}
                      style={styles.logoutButton}
                    >
                      <Text style={styles.logoutButtonText}>Logout</Text>
                    </TouchableOpacity>
                    <ProfileIcon navigation={navigation} />
                  </View>
                ),
              })}
            />
            <Stack.Screen name="Profile" component={ProfileScreen} />
          </>
        ) : (
          <>
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Register" component={RegisterScreen} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerRightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  logoutButton: {
    backgroundColor: '#007AFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    marginRight: 10,
  },
  logoutButtonText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export default function App() {
  return (
    <AuthProvider>
      <AppNavigator />
    </AuthProvider>
  );
}